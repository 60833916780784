<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>
      <div class="group_input_search">
        <Dialouge v-if="item.categoryName">
          <template v-slot:dialogueHead>
            <Input
              :value.sync="item.categoryName"
              :isDisabled="true"
              :maxLength="200"
              @focusout="onFocusout"
            />
          </template>
          <template v-slot:dialogueBody>
            <strong class="tit_dialogue">{{ item.categoryName }}</strong>
            <dl class="info_dialogue">
              <dt>가격 :</dt>
              <dd>
                {{ item.cmdbAssetInfo.price ? item.cmdbAssetInfo.price : "-" }}
              </dd>
              <dt>입고처 :</dt>
              <dd>
                {{ item.cmdbAssetInfo.partnerName ? item.cmdbAssetInfo.partnerName : "-" }}
              </dd>
              <dt>보증기간 :</dt>
              <dd>
                {{ item.cmdbAssetInfo.warranty ? item.cmdbAssetInfo.warranty + "개월" : "-" }}
              </dd>
              <dt>자산상태 :</dt>
              <dd>
                {{ item.cmdbAssetInfo.assetStatus ? item.cmdbAssetInfo.assetStatus : "-" }}
              </dd>
            </dl>
          </template>
        </Dialouge>
        <Input
          v-else
          :value.sync="item.categoryName"
          :isDisabled="true"
          :maxLength="200"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickSearchGoodsName')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <Input
        :value.sync="accountInput"
        :maxLength="200"
        :isDisabled="true"
        @focusout="onFocusout"
      />
    </td>
    <td>
      <Input :value.sync="item.madeIn" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney :value.sync="item.amount" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney :value.sync="item.unitPrice" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney
        :value.sync="item.price"
        :isDisabled="true"
        :maxLength="200"
        @focusout="onFocusout"
      />
    </td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="item.projectCode"
          :isDisabled="true"
          :maxLength="200"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickSearchProjectCode')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <Input :value.sync="item.note" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <button
        class="btn_small btn_fourthly"
        :disabled="isDisabledRemove"
        @click="() => $emit('onClickRemove')"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import Dialouge from "@/_approval/components/common/dialouge/Dialouge";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Input from "@/_approval/components/common/input/Input";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "DraftPurchaseGoodsListLine",
  components: {
    Dialouge,
    InputAutoMoney,
    Input,
  },
  props: {
    index: Number,
    item: Object,
    isDisabledRemove: Boolean,
  },
  computed: {
    accountInput() {
      return this.item.accountName && this.item.accountName
        ? `${this.item.accountName} (${this.item.accountCode})`
        : "";
    },
    price() {
      const { unitPrice, amount } = this.item;

      if (!unitPrice || !amount) return "0";

      const nUnitPrice = getMoneyToNumber(unitPrice);
      const nAmount = getMoneyToNumber(amount);

      const nResult = nUnitPrice * nAmount;

      const strResult = String(nResult);

      return strResult;
    },
  },
  watch: {
    price(value) {
      this.item.price = value;
      this.$emit("updatePrice", this.index);
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
